@import '../_colors.scss';

.logout-btn {
    background-color: $theDarkColor !important;
    color:  #ffffff !important;
    margin: 15px 0 0 15px;
}
.logout-btn-content-div {
    display: flex;
}
.logout-icon {
    margin-left: 5px;
}