@import '../colors';

.login-main {
    display: flex;
    width: 30%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}
.login-form {
    width: 100%;
}
.login-signin-btn {
    margin-top: 40px;
}
.login-spinner {
    color: $mainColor;
}