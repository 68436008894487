@import '../_colors.scss';

.dash-main {
    height: 100%;
    margin-top: 2%;
    margin-right: 5%;
    margin-left: 5%;
}
.dash-table-main {
    width: 100%;
    height: 100%;
}
.dash-table-header {
    display: flex;
    justify-content: space-between;
}
.dash-table-header-text {
    margin-right: 15px;
    margin-top: 15px;
}
.dash-table-header-username {
    font-weight: bold;
}
.dash-accordion-header {
    font-weight: bold;
}
.dash-iframe-back-btn {
    background-color: $theDarkColor !important;
    color: #ffffff !important;
    margin: 15px 0 0 15px;
}
.dash-iframe-main {
    display: flex;
    width: 100%;
    height: 100%;
}
.dash-iframe {
    height: 100%;
    width: 82%;
}
.dash-iframe-side-bar {
    height: 100%;
    width: 18%;
}
.dash-iframe-side-bar-links-main {
    margin: 45px 15px 0 15px;
}
.dash-iframe-side-bar-link {
    text-decoration: underline;
    cursor: pointer;
    width: -webkit-fill-available;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.dash-iframe-back-icon {
    margin-left: 5px;
}
.dash-iframe-back-btn-content-div {
    display: flex;
}
.dash-server-link-main {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    margin-bottom: 10px;
    border-radius: 0.25rem;
    background-color: $theDarkColor;
    color: #ffffff;
}
.dash-server-link-main:hover {
    background-color: #73788f;;
    text-decoration: underline;
    cursor: pointer;
}
.dash-server-link-src {
    font-weight: bold;
}
.dash-server-link-icon {
    margin-left: 20px;
}
.dash-back-btn {
    margin-left: 5px;
}
.bold-link {
    background-color: $theDarkColor !important;
}
.regular-link {
    background-color: #a28089;
}